import React, { forwardRef } from 'react';

import InformationBlock from 'libs/wordpress/content/cmsBlocks/InformationBlock';
import PropTypes from 'prop-types';
import moduleAnimationSettings from 'config/theme/moduleAnimationSettings';
import styled from 'libs/styled';
import useClientHeight from 'hooks/useClientHeight';
import useSiteTheme from 'hooks/useSiteTheme';
import withAnimation from 'utils/withAnimation';

const Wrapper = styled('section')``;

const Module = withAnimation(
    // Proptypes is not working for some reason when forwardRef is used combined with a HOC.
    // eslint-disable-next-line react/prop-types
    forwardRef(({ animateIn = false, content = {}, settings = {}, ...rest }, ref) => {
        const clientHeight = useClientHeight();
        const { module_theme: moduleTheme } = settings;
        useSiteTheme(moduleTheme, animateIn);

        return (
            <Wrapper
                height={['auto', null, null, null, clientHeight, null, `calc(${clientHeight} - 20vh)`]}
                ref={ref}
                {...rest}
            >
                <InformationBlock content={content} />
            </Wrapper>
        );
    }),
    moduleAnimationSettings
);

const InformationModule = ({ settings = {}, ...rest }) => {
    const { module_theme: moduleTheme } = settings;

    return <Module active={!!moduleTheme} settings={settings} {...rest} />;
};

export default InformationModule;

InformationModule.propTypes = {
    settings: PropTypes.object,
};
