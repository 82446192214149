import Arrow from 'assets/icons/Arrow';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { media } from 'utils/mediaqueries';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const StyledLink = styled(Link)`
    display: inline-flex;
    align-items: center;
    gap: 4px;
    transition: gap ${transitions.secondary};

    ${media.hover} {
        :hover {
            gap: 8px;
        }
    }
`;

const ArrowLink = ({ children, iconHeight, iconWidth, to, ...rest }) => {
    const styles = styleObjectToProps('Value Serif/16');

    return (
        <StyledLink as={!to && 'button'} to={to} {...styles} {...rest}>
            {children}
            <Arrow color="currentColor" height={iconHeight} width={iconWidth} />
        </StyledLink>
    );
};

ArrowLink.propTypes = {
    children: PropTypes.node.isRequired,
    iconHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    iconWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    to: PropTypes.string,
};

export default ArrowLink;
