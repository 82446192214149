import ArrowLink from 'components/text/ArrowLink';
import PropTypes from 'prop-types';
import React from 'react';
import ThemeButton from 'components/buttons/ThemeButton';
import { buttonsProp } from 'utils/proptypes/modules/buttonsProps';
import styled from 'libs/styled';

const Wrapper = styled('div')``;

const allowedButtons = {
    ThemeButton,
    ArrowLink,
};

const ButtonsList = ({ buttons = [], type = 'ThemeButton', ...rest }) => {
    if (!buttons?.length > 0) {
        return null;
    }

    const Component = allowedButtons[type];

    return (
        <Wrapper display="flex" gap="8px" {...rest}>
            {buttons.map((button, index) => (
                <Component key={`${button.label}-${index}`} theme={button.theme} to={button.to}>
                    {button.label}
                </Component>
            ))}
        </Wrapper>
    );
};

ButtonsList.propTypes = {
    buttons: buttonsProp,
    type: PropTypes.oneOf(['ThemeButton', 'ArrowLink']),
};

export default ButtonsList;
