import { headingTagProp, wysiwygProp } from 'utils/proptypes/modules/textProps';

import BackgroundSwitch from 'components/background/BackgroundSwitch';
import ButtonsList from 'components/buttons/ButtonsList';
import ExpandWrapper from 'components/wrappers/Animations/ExpandWrapper';
import Heading from 'components/text/Heading';
import PropTypes from 'prop-types';
import React from 'react';
import Tag from 'components/text/Tag';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import WysiwygParagraph from 'components/text/WysiwygParagraph';
import { backgroundFlexibleProp } from 'utils/proptypes/modules/mediaProps';
import { buttonsProp } from 'utils/proptypes/modules/buttonsProps';
import contentMargins from 'config/theme/contentMargins';
import { mediaQueries } from 'config/theme/breakpoints';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';

const Div = styled('div')``;

/**
 *  ContentBlock used to display image, heading, text, tag and buttons.
 *
 * @param {object} background - Background image as object.
 * @param {array} buttons - Buttons as array.
 * @param {string} heading - Heading as a string.
 * @param {string} headingTag - The html-tag used by heading; h1, h2, h3 etc.
 * @param {array} horizontalAlignment - Used to set the module layout
 * @param {string} tag - Small tag displayed below heading.
 * @param {array} text - Text as a wysiwyg-array.
 * @param {array} verticalAlignment - Used to set the text and buttons layout
 */

// @todo: Add module expansion and background theme
const Information = ({
    background = {},
    backgroundType,
    buttons = [],
    verticalAlignment = 'top',
    horizontalAlignment = 'left',
    heading = '',
    headingTag = '',
    tag = '',
    text = '',
}) => {
    let itemsAlignment;
    switch (verticalAlignment) {
        case 'top':
            itemsAlignment = 'flex-start';

            break;
        case 'center':
            itemsAlignment = 'space-between';
            break;
        default:
            itemsAlignment = 'flex-end';
    }
    return (
        <Div
            display="flex"
            flexDirection={['column', null, null, null, 'row']}
            gap={['none', null, 'none', null, '24px']}
            height="100%"
        >
            <ExpandWrapper
                aspectRatio={[ratios.vertical, null, ratios.horizontal, null, 'none']}
                order={['1', null, null, null, horizontalAlignment === 'left' ? '2' : '1']}
                width={['100%', null, null, null, 'calc(66.66666667% - 8px)']}
            >
                <BackgroundSwitch type={backgroundType} background={background} mediaQuery={mediaQueries.twoColumn} />
            </ExpandWrapper>
            <Div
                display="flex"
                flexDirection="column"
                justifyContent={['normal', null, null, null, itemsAlignment]}
                order={['2', null, null, null, horizontalAlignment === 'left' ? '1' : '2']}
                padding={[
                    `24px ${contentMargins[0]}`,
                    null,
                    `32px ${contentMargins[2]}`,
                    null,
                    `24px 16px 24px ${contentMargins[2]}`,
                ]}
                width={['100%', null, null, null, 'calc(33.33333333% - 8px)']}
            >
                <Div>
                    {heading && (
                        <Heading
                            as={headingTag}
                            fontKeys={[
                                'Value Serif/40-scalable-mobile',
                                null,
                                'Value Serif/56-scalable-tablet',
                                null,
                                'Value Serif/56-scalable-desktop',
                            ]}
                            marginBottom="16px"
                        >
                            {heading}
                        </Heading>
                    )}
                    {tag && <Tag marginBottom="16px">{tag}</Tag>}
                    {text && (
                        <Div marginBottom="24px" maxWidth="600px" order={['1', null, null, null, '2']}>
                            <Wysiwyg
                                data={text}
                                tagComponents={{ url: TextLinkWysisyg }}
                                textComponent={props => <WysiwygParagraph {...props} />}
                            />
                        </Div>
                    )}
                </Div>
                <Div display="flex" flexDirection="column">
                    {buttons && (
                        <ButtonsList
                            buttons={buttons}
                            flexWrap="wrap"
                            marginBottom={['24px', null, null, null, '0px']}
                            order={['1', null, null, null, '2']}
                        />
                    )}
                </Div>
            </Div>
        </Div>
    );
};

Information.propTypes = {
    background: backgroundFlexibleProp,
    backgroundType: PropTypes.string,
    buttons: buttonsProp,
    heading: PropTypes.string.isRequired,
    headingTag: headingTagProp,
    horizontalAlignment: PropTypes.oneOf(['left', 'right']),
    tag: PropTypes.string,
    tagSize: PropTypes.string,
    text: wysiwygProp,
    verticalAlignment: PropTypes.oneOf(['top', 'center', 'bottom']),
};

export default Information;
